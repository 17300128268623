const currencyFormat = (val, options) => {
  const { noLabel = false, noCents = false } = options || {};

  if (noLabel && noCents) {
    return Number(val).toLocaleString();
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "LKR",
  });

  return formatter.format(val);
};

export default currencyFormat;
